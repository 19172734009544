body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #639b7e;
    color: #2e8b57;
    height: 100%;
    width: 100%;
  }

html, #root {
    height: 100%;
    width: 100%;
}
  
.main-page {
    width: fit-content;
  }

  .top_half {
    background-image: url('https://plantshout-199a76bab95e.herokuapp.com/assets/Top_half_background_two.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /*border-bottom: 5px solid black;*/
  }

header {
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    /*background-color: #2e8b57;
    background-color: rgba(46, 139, 86, 0.5);*/
    color: rgb(0, 0, 0);
    padding: 60px 20px;
  }

.info {
    display: flex;
    flex-direction:column;
    align-items: center;
    text-align: center;
    padding: 20px 30px;
    /*border-bottom: 5px solid #3cb371;
    background-color: rgba(255, 255, 255, 0.6);*/
    color: rgb(0, 0, 0);
    background-color: rgb(248 143 113 / 75%);
    margin-left: 8vw;
    margin-right: 8vw;
    border-radius: 10vw;
  }

  .info h2 {

    text-shadow: 3px 3px 4px rgb(255, 255, 255);
  }
  header h1 {
    text-shadow: 3px 3px 4px rgb(255, 255, 255);
    padding-top: 2vh;
  }

.post-form {
    /*background-color: white;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #3cb371;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 2vw;
    margin-left: 3vw;
    margin-right: 3vw; */
    padding: 20px;
  }

.post-form h2 {
    color: #000000;
    margin-top: 0;
    text-align: center;
    text-shadow: 3px 3px 4px rgb(255, 255, 255);
  }

.post-form form {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #3cb371;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 2vw;
    margin-left: 3vw;
    margin-right: 3vw;
  }

.post-form form select,
  .post-form form input,
  .post-form form textarea,
  .post-form form button {
    display: block;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #2e8b57; /* Dark green border */
    border-radius: 5px;
    font-size: 16px;
  }

  .post-form button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  .post-form button:hover {
    background-color: #45a049;
  }

  .search-container {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #3cb371;
    padding: 20px;
    border-radius: 5px;
    box-shadow: -30px -20px 4px 0 #e1ca5c, -8px 27px 4px 0 #b0ccbd, 30px -9px 4px 0 #fd906f;
    margin-left: 3vw;
    margin-right: 3vw;
    margin-bottom: 3vw;
  }

  .loader {
    text-align: center;
    margin-top: 20px;
  }
  
  .loader img {
    width: 7vw;
    height: 7vw;
    /*animation: spin 1s linear infinite;*/
  }
  
 /* @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  */

.search-container input {
    padding: 10px;
    border: 1px solid #2e8b57; /* Dark green border */
    border-radius: 5px;
    font-size: 16px;
    flex: 1;
  }

  .search-container select {
    padding: 10px;
    border: 1px solid #2e8b57; /* Dark green border */
    border-radius: 5px;
    font-size: 16px;
    width: 100px;
    margin-left: 1vw;
  }

  /*
  .search-container label {
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
  }
  */

  .toggle-user-posts {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    padding-left: 1vw;
  }
  
  .toggle-user-posts img {
    width: 5vw; /* Adjust the size */
    height: auto;
    transition: opacity 0.2s ease-in-out;
  }
  
  .toggle-user-posts p {
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #555;
    text-align: center;
  }
  
  .toggle-user-posts:hover {
    transform: scale(1.1);
  }
  
  .feed {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #629b7d;
    /*background-image: url('https://plantshout-199a76bab95e.herokuapp.com/assets/feed_background.png');*/
    background-size: fill;
    background-position: center;
    background-repeat: repeat-y;
    
  
  }

  .feed img{
    border: 4px outset #96df41
  }

  .feed form {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #2e8b57; /* Dark green border */
    border-radius: 5px;
    background-color: #f0fff0; /* Light green background */
  }

  .feed form textarea {
    width: 100%
  }

  .post {
    /*background-color: white;*/
    padding: 20px;
    padding-top: 30px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.66);
    margin-bottom: 20px;
    margin-top: 20px;
    border: 1.5vw outset #f67d60;
    margin-left: 3vw;
    margin-right: 3vw;
    border-radius: 5px;  
    /*background-image: url('https://plantshout-199a76bab95e.herokuapp.com/assets/feed_background3.png');
    background-size: contain;
    background-position: center;
    background-repeat: repeat-y;
    */
    background-color: rgba(255, 255, 255, 0.929);
  }

  .post.completed {
    background-color: #e6ffe6; /* Light green background for completed posts */
    border: 1.5vw inset #40dc47; /* Green border */
  }  

  .feed button {
    background-color: #4caf50;
    color: white;

  }

  .feed button:hover {
    background-color: #45a049;
  }

  .feed h2{
    text-align: center;
    text-decoration: underline;
    color: rgb(46, 161, 3);
  }

  .feed h3{
    text-align: center;
    color: rgb(46, 161, 3);
  }

  .comment_divider h3{
    text-align: center;
    color: rgb(75, 181, 219);
    padding-top: 2vw;
  }

  .post h2 {
    margin-top: 0;
  }

  .post-image {
    display: flex;
    justify-content: center; /* Horizontally center the image */
    margin-top: 10px;
  }

  .post-image img {
    box-shadow: -21px -16px 9px 0px #9dd57b, 15px 24px 5px 0px #E05F45;
    border: 4px outset rgb(75, 181, 219);
  }

  .post-header {
    position: relative;
    align-items: center;
    margin-bottom: 10px;
  }

  .post-header label {
    position: absolute;
    left: 83%;
    top: 30%;
    transform: translateY(-50%);
    font-size: 1rem;
    font-weight: bold;
  }
  
  .user-picture img {
    left: 0;
    top: 50%;   
    border-radius: 30%;
    width: 6vw;
  }
  
  .comment img {
    width: 4vw;
}

  .post-title {
    flex: 1;
    text-align: center;
    justify-content: center;
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .image-preview img {
    display: flex;
    justify-content: space-evenly;
  }

  .image-preview {
    display: flex;
    justify-content: space-evenly;
  }
  

  .comments {
    margin-top: 20px;
  }

  .post {
    font-size: 1.1vw;
  }

  .comment {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #2e8b57; /* Dark green border */
    border-radius: 5px;
    background-color: #f0fff0; /* Light green background */
  }

  .AI_picture img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }


  .make-post-button {
    background-color: #3cb371; /* Medium sea green */
    color: #ffffff; /* White text */
    padding: 15px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .make-post-button:hover {
    background-color: #2e8b57; /* Dark green */
  }

  img[alt="AphidAI"] {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

.login-register {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('https://plantshout-199a76bab95e.herokuapp.com/assets/login_background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.login-register form {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.8); /* White background with 80% opacity */
    border: 1px solid #3cb371; /* Medium sea green border */
    border-radius: 10px;
    padding: 30px;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-left: 5vw;
    margin-right: 5vw;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-register h1 {
  color: #d86b58; /* Medium sea green */
  text-align: center;
  justify-content: center;
  margin-left: 35vw;
  margin-right: 35vw;
  text-shadow: 0 0 1px #73f47e, 0 0 1px #73f47e, 0 0 1px #73f47e, 0 0 1px #73f47e
}

.login-register h2 {
  color: #35953d; /* Medium sea green */
  text-shadow: 0 0 1.5px #ffffff, 0 0 1.5px #ffffff, 0 0 1.5px #ffffff, 0 0 1.5px #ffffff;
  text-align: center;
  padding-left: 2vw;
  padding-right: 2vw;
  margin-left: 28vw;
  margin-right: 28vw;
}

.login-register form input, .login-register form button {
    display: block;
    margin: 10px 0px;
    padding: 10px;
    border: 1px solid #2e8b57; /* Dark green border */
    border-radius: 5px;
}

.login-register form button {
    background-color: #3cb371; /* Medium sea green */
    color: #ffffff; /* White text */
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;  
}

.login-register form button:hover {
    background-color: #2e8b57; /* Dark green */
}

.login-register h3 {
    text-align: center;
    color: #2e8b57; /* Dark green */
    cursor: pointer;
}

/* For tablets and small desktops */
@media (max-width: 768px) {
  .login-register {
    background-position: center;
    background-size: cover;
    /*
    background-image: none;
    background-color: #e0ffe0;
    */
  }

  .login-register form {
    padding: 20px;
    margin-left: 2vw;
    margin-right: 2vw;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }

  .login-register h1 {
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .login-register h2 {
    margin-left: 5vw;
    margin-right: 5vw;
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .post {
    border: 4vw outset #f67d60;
    font-size: 2.5vw;
  }
  .toggle-user-posts img {
    width: 10vw; /* Adjust the size */

  }
}

@media (max-width: 590px) {
  .post-header label {
    font-size: .9rem;
  }
  .search-container {
    padding: 13px;
  }

  .comment img {
    width: 9vw;
}

.user-picture img {
  width: 10vw;
}

}

/* For mobile devices */
@media (max-width: 480px) {
  .login-register {
    background-position: center;
    background-size: cover;
    padding: 12px;
  }

  .login-register form {
    padding: 15px;
    margin-left: 2vw;
    margin-right: 2vw;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  }

  .login-register h1 {
    font-size: 1.5rem;
    margin-left: 0;
    margin-right: 0;
  }

  .login-register h2 {
    font-size: 1.2rem;
    margin-left: 0;
    margin-right: 0;
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .login-register form input, 
  .login-register form button {
    padding: 8px;
    font-size: 14px;
  }

  .post {
    font-size: 4vw;
  }

  .toggle-user-posts img {
    width: 15vw; /* Adjust the size */

  }

  .comment img {
    width: 14vw;
  }

  .user-picture img {
    width: 15vw;
  }
}

